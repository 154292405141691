





























































































































































import {
  SfHeading,
  SfTable,
  SfButton,
  SfDivider,
  SfPrice,
  SfImage,
  SfNotification
} from '@storefront-ui/vue';
import {
  ref,
  computed,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
} from '@nuxtjs/composition-api';

import * as Sentry from '@sentry/browser';
import SfProperty from '~/components/Overrides/Atoms/SfProperty.vue';
import { Logger } from '~/helpers/logger';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import { useImage } from '~/composables';
import useMakeOrder from '~/modules/checkout/composables/useMakeOrder';
import useCart from '~/modules/checkout/composables/useCart';
import getShippingMethodPrice from '~/helpers/checkout/getShippingMethodPrice';
import { removeItem } from '~/helpers/asyncLocalStorage';
import { isPreviousStepValid } from '~/helpers/checkout/steps';
import VsfPaymentProvider from '~/modules/checkout/components/VsfPaymentProvider.vue';
import type { BundleCartItem, ConfigurableCartItem, CartItemInterface } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'ReviewOrderAndPayment',
  components: {
    SfHeading,
    SfTable,
    SfButton,
    SfDivider,
    SfPrice,
    SfProperty,
    SfImage,
    VsfPaymentProvider,
    SfNotification
  },
  setup() {
    const paymentProviderRef = ref(null);
    const order = ref(null);
    const orderErrorVisible = ref(false);
    const orderErrorMessage = ref(null);
    const { cart, load, setCart } = useCart();
    const { make, loading } = useMakeOrder();
    const { app } = useContext();
    const router = useRouter();
    const isPaymentReady = ref(false);
    const getAttributes = (product: ConfigurableCartItem) => product.configurable_options || [];
    const getBundles = (product: BundleCartItem) => product.bundle_options?.map((b) => b.values).flat() || [];
    const products = computed(() => cartGetters
      .getItems(cart.value)
      .filter(Boolean)
      .map((item) => ({
        ...item,
        product: {
          ...item.product,
          ...[(item as ConfigurableCartItem).configured_variant ?? {}],
          original_sku: item.product.sku,
        },
      })));

    onMounted(async () => {
      const validStep = await isPreviousStepValid('billing');
      if (!validStep) {
        await router.push(app.localePath('/checkout/user-account'));
      }

      await load();
    });

    const processOrder = async () => {
      try {
        app.$gtm?.push({
          event: 'add_payment_info',
          value: cart?.value?.prices?.subtotal_excluding_tax?.value,
          currency: app.$vsf.$magento.config.state.getCurrency() ?? 'USD',
          // @ts-ignore
          tax: cart?.value?.prices?.applied_taxes?.map(tax => ({
            [tax?.label]: tax?.amount?.value
          })),
          items: products.value.map(product => {
            return {
              item_name: product?.product?.name,
              item_id: product?.product?.sku,
              price: product?.prices?.row_total?.value,
              quantity: product?.quantity,
              // @ts-ignore
              item_variant: product?.configured_variant?.sku
            };
          })
        });
      } catch (e) {
        Logger.error(e);
      }

      try {
        if (cart?.value?.prices?.grand_total.value > 0) {
          await paymentProviderRef.value.getPaymentData();
        } else {
          await paymentProviderRef.value.definePaymentMethods('free');
        }
      } catch (e) {
        Logger.error(e);
        orderErrorMessage.value = e.message ? e.message : 'There was unexpected error in processing your order. Please try again or contact with us.';
        app.$gtm?.push({
          event: 'order_error',
          value: cart?.value?.prices?.grand_total?.value,
          currency: app.$vsf.$magento.config.state.getCurrency() ?? 'USD',
          message: orderErrorMessage.value
        });
        orderErrorVisible.value = true;
        return;
      }

      try {
        orderErrorVisible.value = false;
        order.value = await make();
      } catch (e) {
        Sentry.captureException(e);
        orderErrorMessage.value = String(e) || 'There was unexpected error in processing your order. Please try again or contact with us.';
        orderErrorVisible.value = true;
        app.$gtm?.push({
          event: 'order_error',
          value: cart?.value?.prices?.grand_total?.value,
          currency: app.$vsf.$magento.config.state.getCurrency() ?? 'USD',
          message: orderErrorMessage.value
        });
        return;
      }

      if (!order.value) {
        orderErrorMessage.value = 'There was unexpected error in processing your order. Please try again or contact with us.';
        orderErrorVisible.value = true;
        app.$gtm?.push({
          event: 'order_error',
          value: cart?.value?.prices?.grand_total?.value,
          currency: app.$vsf.$magento.config.state.getCurrency() ?? 'USD',
          message: orderErrorMessage.value
        });
        return;
      }

      try {
        app.$gtm?.push({
          event: 'purchase',
          value: cart?.value?.prices?.grand_total?.value,
          currency: app.$vsf.$magento.config.state.getCurrency() ?? 'USD',
          tax: cart?.value?.prices?.applied_taxes?.map(tax => ({
            [tax?.label]: tax?.amount?.value
          })),
          // coupon:
          transaction_id: order.value?.order?.order_number,
          items: products.value.map(product => {
            return {
              item_name: product?.product?.name,
              item_id: product?.product?.sku,
              price: product?.prices?.row_total?.value,
              quantity: product?.quantity,
              // @ts-ignore
              item_variant: product?.configured_variant?.sku
            };
          })
        });
      } catch (e) {
        Logger.error(e);
      }

      setCart(null);
      app.$vsf.$magento.config.state.removeCartId();
      await load();
      await removeItem('checkout');
      const thankYouRoute = app.localeRoute({
        name: 'thank-you',
        query: {
          order: order.value.order.order_number,
        },
      });
      await router.push(thankYouRoute);
    };

    const discounts = computed(() => cartGetters.getDiscounts(cart.value));
    const hasDiscounts = computed(() => discounts.value.length > 0);
    const discountsAmount = computed(
      () => -1 * discounts.value.reduce((a, el) => el.value + a, 0),
    );

    const { getMagentoImage, imageSizes } = useImage();
    const getRowTotal = (product: CartItemInterface) => cartGetters.getItemPrice(product).regular - cartGetters.getItemPrice(product).special;
    return {
      cart,
      cartGetters,
      discounts,
      hasDiscounts,
      discountsAmount,
      getShippingMethodPrice,
      isPaymentReady,
      loading,
      paymentProviderRef,
      processOrder,
      products: computed(() => cartGetters.getItems(cart.value)),
      selectedShippingMethod: computed(() => cartGetters.getSelectedShippingMethod(cart.value)),
      tableHeaders: ['Description', 'Quantity', 'Amount'],
      totals: computed(() => cartGetters.getTotals(cart.value)),
      total: computed(() => cartGetters.getTotals(cart.value).total),
      getAttributes,
      getBundles,
      getMagentoImage,
      imageSizes,
      getRowTotal,
      orderErrorVisible,
      orderErrorMessage
    };
  },
  data() {
    return {
      payment: {}
    };
  },
  head: {
    title: 'Checkout at SwiftOtter'
  },
  methods: {
    receivePaymentInfo(gatewayResponse) {
      this.payment = gatewayResponse;
    }
  },
});
