
































// import { SfRadio } from '@storefront-ui/vue';
import { ref, onMounted, defineComponent } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import usePaymentProvider from '~/modules/checkout/composables/usePaymentProvider';
import type { AvailablePaymentMethod } from '~/modules/GraphQL/types';
import BraintreeMethod from '~/modules/checkout/components/PaymentMethods/BraintreeMethod.vue';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';

export default defineComponent({
  name: 'VsfPaymentProvider',
  components: {
    // SfRadio,
    BraintreeMethod,
    SkeletonLoader
  },
  emits: ['status'],
  setup(_props, { emit }) {
    const paymentMethods = ref<AvailablePaymentMethod[]>([]);
    const braintreeDropin = ref(null);
    const { load, save } = usePaymentProvider();
    const selectedPaymentMethodCode = ref<string | null>(null);
    const payment = ref(null);
    const currentPaymentParams = ref(null);

    const getPaymentParamsByCode = (paymentMethodCode, requireParams = false) => {
      if (requireParams && !currentPaymentParams.value) {
        return false;
      }

      const params = {
        braintree: {
          braintree: {
            ...currentPaymentParams.value
          }
        },
      };

      return params[paymentMethodCode]
        ? params[paymentMethodCode]
        : {};
    };

    onMounted(async () => {
      paymentMethods.value = await load();
    });

    const definePaymentMethods = async (paymentMethodCode: string) => {
      const methodsRequiringParams = ['braintree'];
      const paramsAreRequired = methodsRequiringParams.includes(paymentMethodCode);
      const params = await getPaymentParamsByCode(paymentMethodCode, paramsAreRequired);
      const previousMethod = selectedPaymentMethodCode.value;
      selectedPaymentMethodCode.value = paymentMethodCode;

      if (paramsAreRequired && !params) {
        emit('status', paymentMethodCode);
        return true;
      }

      try {
        const paymentMethodsResults = await save({
          paymentMethod: {
            code: paymentMethodCode,
            ...params
          },
        });

        if (paymentMethodsResults) {
          paymentMethods.value = paymentMethodsResults;
        }

        selectedPaymentMethodCode.value = paymentMethodCode;
        emit('status', paymentMethodCode);
      } catch (e) {
        Logger.error(e);
        selectedPaymentMethodCode.value = previousMethod;
        return true;
      }
    };

    return {
      braintreeDropin,
      currentPaymentParams,
      definePaymentMethods,
      payment,
      paymentMethods,
      selectedPaymentMethodCode
    };
  },
  methods: {
    receivePaymentInfo(gatewayResponse) {
      this.payment = gatewayResponse;
      // as we do not use radio buttons to select payment methods
      // select braintree when it's initialized
      this.definePaymentMethods('braintree');
    },

    getPaymentData() {
      return new Promise((resolve, reject) => {
        this.payment.instance.requestPaymentMethod(async (err, payload) => {
          if (err) {
            Logger.error('Braintree request errors: ', err);
            reject(err);
          } else {
            Logger.info('Braintree request was successful.');
            this.currentPaymentParams = {
              payment_method_nonce: payload.nonce,
              is_active_payment_token_enabler: false
            };
            await this.definePaymentMethods(this.selectedPaymentMethodCode);
            resolve(payload);
          }
        });
      });
    }
  }
});
