<template>
  <div>
    <div
      id="braintree-container"
      ref="dropIn"
      class="payment-form"
    />
  </div>
</template>

<script>
import braintree from 'braintree-web-drop-in';
import { computed, onMounted } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { useTestAppStore } from '@/stores/app';
import { usePurchaseStore } from '@/stores/purchase';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import useCart from '~/modules/checkout/composables/useCart';
import { useMagentoConfiguration } from '~/composables';

export default {
  name: 'BraintreeMethod',

  setup() {
    const { cart, load } = useCart();
    const { selectedCurrency } = useMagentoConfiguration();

    onMounted(async() => {
      try {
        await load();
      } catch (e) {
        Logger.error(e?.message);
        throw new Error('There was unexpected problem with loading cart. Please reload the page and try again.');
      }
    });

    return {
      purchaseStore: usePurchaseStore(),
      testAppStore: useTestAppStore(),
      totals: computed(() => cartGetters.getTotals(cart.value)),
      selectedCurrency,
    };
  },
  data() {
    return {
      dropin: '',
      initialized: false
    };
  },
  computed: {
    paymentData() {
      return {};
    },
    price() {
      return this.totals.total;
    },
    decimalAmount() {
      return this.totals.total;
    }
  },
  watch: {
    paymentData() {
      this.initPayment();
    },
    decimalAmount(value) {
      if (!this.dropin) {
        this.initPayment();
      } else {
        this.dropin.updateConfiguration('paypalCredit', 'amount', value);
        this.dropin.updateConfiguration('paypal', 'amount', value);
      }
    }
  },
  beforeDestroy() {
    this.$refs.dropIn.innerHTML = '';
    this.dropin = '';
    this.initialized = false;
  },
  mounted() {
    if (this.paymentData) {
      this.initPayment();
    }
  },
  methods: {
    initPayment() {
      if (!this.paymentData || this.paymentData.price <= 0 || this.initialized || this.initializing || !process.client) {
        return;
      }
      this.initializing = true;

      if (!this.$refs.dropIn) {
        Logger.warn('No refs yet');
        this.$nextTick(this.initPayment.bind(this));
      }

      if (this.dropin) {
        this.dropin.updateConfiguration('paypalCredit', 'amount', this.decimalAmount);
        this.dropin.updateConfiguration('paypal', 'amount', this.decimalAmount);

        this.initializing = false;
        return;
      }

      const paypalEnabled = process.env.PAYPAL_ENABLED === 'true'; // this.selectedCurrency === 'USD';

      this.testAppStore.getToken().then(authorization => {
        braintree.create({
          authorization,
          dataCollector: {
            kount: true,
            paypal: paypalEnabled
          },
          applePay: {
            displayName: 'SwiftOtter Practice Tests',
            paymentRequest: {
              total: {
                label: 'SwiftOtter Practice Tests',
                amount: this.price,
              },
              requiredBillingContactFields: ['postalAddress'],
              currencyCode: this.selectedCurrency
            },
          },
          container: this.$refs.dropIn,
          threeDSecure: false,
          venmo: {
            allowNewBrowserTab: false
          },
          ...(
            paypalEnabled
              ? {
                  paypal: {
                    flow: 'checkout',
                    amount: this.decimalAmount,
                    currency: this.selectedCurrency
                  },
                  paypalCredit: {
                    flow: 'checkout',
                    amount: this.decimalAmount,
                    currency: this.selectedCurrency
                  }
                }
              : {}
          )
        }, (error, instance) => {
          if (instance) {
            this.dropin = instance;
          }
          Logger.debug('Braintree response', this.dropin);
          this.$emit('response', {
            error,
            instance
          });
          if (typeof error === 'object' && error) {
            this.initializing = false;
            Logger.error('Braintree', error.name, error.message);
          } else if (error) {
            this.initializing = false;
            Logger.error('Braintree', error);
          } else {
            Logger.warn('Braintree failed to initialize', error);
          }
        });

        this.initializing = false;
        this.initialized = true;
      });
    }
  }
};
</script>

<style scoped lang="scss">
.payment-form {
  min-height: 130px;
}

.braintree-heading {
  display: none;
}

.braintree-placeholder {
  display: none;
}
</style>
